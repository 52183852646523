<div class="feedback-container">
  <button
    mat-icon-button
    class="btn-w-icon-append"
    id="feedback-close-btn"
    (click)="close()"
    [title]="'COMMON.BUTTON.DIALOG.CLOSE' | translate"
    [attr.aria-label]="'COMMON.BUTTON.DIALOG.CLOSE' | translate"
  >
    <mat-icon class="icon-smd">close</mat-icon>
  </button>
  <a
    mat-button
    target="_blank"
    rel="noopener noreferrer"
    [href]="'FOOTER.LINKS.FEEDBACK.URL' | translate"
    id="feedback-btn"
  >
    {{ 'COMMON.BUTTON.FEEDBACK' | translate }}
    <mat-icon iconPositionEnd class="icon-smd">insert_comment</mat-icon>
  </a>
</div>
