import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { SNAME } from '@core/constants/storage';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'pbb-feedback-button',
  templateUrl: './feedback-button.component.html',
  styleUrls: ['./feedback-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButtonModule, MatIconModule, TranslateModule],
})
export class FeedbackButtonComponent {
  @Output() close$ = new EventEmitter();
  close() {
    sessionStorage.setItem(SNAME.SHOW_FEEDBACK_BUTTON, 'false');
    this.close$.emit();
  }
}
